body {
  font-family: "Lato Light", sans-serif;
  font-size: 30px;
  height:100vh;
}

.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  position: relative;
}
.content {
  flex: 1;
  padding-bottom: 60px;
}

.footer {
  flex-shrink: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: baseline;
  position: absolute;
}
.center {
    position: absolute;
    top: 30%;
    left: 20%;
    /* transform: translate(-50%, -50%); */
    font-size: 40px;
    color:white;
    font-weight: bold;
  }
  .center2 {
    position: absolute;
    top: 50%;
    left: 20%;
    /* transform: translate(-50%, -50%); */
    font-size: 40px;
    color:white;
    font-weight: bold;
  }
  .center3 {
    position: absolute;
    top: 65%;
    left: 20%;
    /* transform: translate(-50%, -50%); */
    font-size: 40px;
    color:white;
    font-weight: bold;
  }
  .center4 {
    position: absolute;
    /* transform: translate(-50%, -50%); */
    font-size: 40px;
    color:white;
    font-weight: bold;
  }

.img { 
    width: 100%;
    /* height: 40%; */
    /* margin-left: 10%; */
    opacity: 1;
  }

 .button {
    margin-top: 20px;
    left: 20%;
    height: 50px;
    width: 15%;
    background-color: rgb(231, 241, 232);
    /* background-color:#81b566; */ 
  }

  .navbar{
    height: 50;
    background-color: rgb(226, 33, 28);
    display: flex;
    /* justify-content: center; */
  }

  .navContainer{
    width:100%;
    color: white;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .logo{
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-family: "Lato Light", sans-serif;
    margin: auto;
  }
  
  .highlight {
    color: #d10000;
 }

 /* btn */

 .btn {
   width: 200px;
   font-size: 36px;
   height: 50px;
   border: none;
   outline: none;
   /* background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5); */
   background-color: rgb(226, 33, 28);
   
   background-size: 400%;
   /* filter: blur(5px); */
   animation: move 60s linear infinite;
   color: 'black';
   cursor: pointer;
   position: relative;
   z-index: 0;
   border-radius: 10px;

 }

 @font-face {
  font-family: 'Lato Light';
  src: local('Lato'), url(./fonts/Lato-Light.ttf) format('truetype');
}
/* 
.btn::before {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  left: -2px;
  top: -2px;
  background: linear-gradient(
    124deg,
    #ff2400,
    #e81d1d,
    #e8b71d,
    #e3e81d,
    #1de840,
    #1ddde8,
    #2b1de8,
    #dd00f3,
    #dd00f3
  );
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  animation: move 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.btn:hover::before {
  opacity: 1;
}

.btn::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;

  left: 0;
  top: 0;
  border-radius: 10px;
} */

/* for border */
.gradient-border {
  --borderWidth: 3px;
  background: #1D1F20;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}



@keyframes move {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* .footer {
  display: flex;
  align-items: flex-end;
  place-content: end;
  padding-bottom:5px;
  padding-right:5px;
  position: absolute; 
  bottom: 10px;
  width: 500px
} */

.line-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
}

.line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black;
}

.word-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  padding: 0 10px;
}

.word {
  font-size: 20px;
  font-weight: bold;
}

@media all and (max-width: 500px) {
  .displayWidth {
    /* max-width: '500'; */
    max-width: 500;
    min-Width: 400;
    min-Height: 600;

  }

  /* .footer {
    right: 0; 
    max-width: 500px;
  } */
}

.holiday {
  color: red;
}