.MuiTableCell-root {
    display: table-cell;
    /* padding: 10px !important; */
    font-size: 16 !important;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    /* min-height: 100vh !important; */
}
.MuiTableCell-body{
    /* min-height: 100vh !important; */

}

.MuiBox-root-9{
    min-height: 100vh !important;
}


.MuiBox-root-7{
    padding: 10px !important;
    /* min-height: 100vh !important; */
}

.MuiTableCell-alignLeft{
    padding: 8px !important;
}

*{
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }